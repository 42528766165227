import * as React from "react"
import {FC, useCallback, useEffect, useRef, useState} from "react"
import '../neat-basics/index.scss'
import "../pages/theming.scss"
import "../styles/styles.scss"
import "../elements/pagination.scss"
import SubPageHeader from "../modules/sub-page-header"
import Page from "../modules/page"
import Section from "../modules/section"
import Teaser, {Type} from "../modules/teaser"
import Navigation from "../modules/navigation";
import {
    Article,
    ArticleOrNews,
    News,
    PaginatedArticleQueryResult,
    TEMPLATE_KEY_THOUGHT_ARTICLE
} from "../types";
import {getThoughts, getThoughtsAndNews} from "../util";
import Footer from "../modules/footer";
import NewsItem from "../modules/news-item";
import {graphql, Link} from "gatsby";
import MobileNavigation from "../modules/mobile-navigation";
import Main from "../modules/main";
import "./news_and_thoughts.scss"
import Loadable from "@loadable/component"

const LoadableMasonryGrid = Loadable(() => import("../elements/masonry-grid"))

const NewsAndThoughtsPage: FC<PaginatedArticleQueryResult> = (page) => {
    const thoughts = getThoughts(page);
    const firstThought = thoughts.shift();
    const thoughtsAndNews = getThoughtsAndNews(page);
    const [lastImageUpdateTimestamp, setLastImageUpdateTimestamp] = useState();

    function removeFirstArticle(articleOrNews: ArticleOrNews[], firstThought: Article | undefined): ArticleOrNews[] {
        if(page.pageContext.pageNumber !== 0) {
            return articleOrNews;
        }

        if(!firstThought) {
            return articleOrNews;
        }

        return articleOrNews.filter(a => "fields" in a && a.fields.slug !== firstThought.fields.slug);
    }

  return (
    <Main title="News and thoughts">
      <Page>
        <SubPageHeader title="News and thoughts">
          <Navigation activePage="newsandthoughts"/>
          <MobileNavigation activePage="newsandthoughts"/>
        </SubPageHeader>
         <Section>
             {firstThought  && page.pageContext.pageNumber === 0 &&
             <Teaser tag="RECENT THOUGHT" image={firstThought.frontmatter.image} type={Type.LARGE}
                     date={firstThought.frontmatter.date} title={firstThought.frontmatter.title} text={firstThought.excerpt}
                     actionPath={firstThought.fields.slug}/>
             }

           <LoadableMasonryGrid lastImageUpdateTimestamp={lastImageUpdateTimestamp}>
               <div className="news-and-thoughts__grid-sizer"></div>
               {removeFirstArticle(thoughtsAndNews, firstThought).map( articleOrNews => <NewsAndThoughtItem {...articleOrNews} key={articleOrNews.id} setLastImageUpdateTimestamp={setLastImageUpdateTimestamp}/>)}
            </LoadableMasonryGrid>
             <div className="pagination">
                 <div className="pagination__element-container">{ page.pageContext.previousPagePath && <Link className="pagination__button pagination__button--prev" to={page.pageContext.previousPagePath}>‹</Link>}</div>
               <div className="pagination__number">{page.pageContext.humanPageNumber} / {page.pageContext.numberOfPages}</div>
               <div className="pagination__element-container"> { page.pageContext.nextPagePath && <Link className="pagination__button pagination__button--next"  to={page.pageContext.nextPagePath}>›</Link>} </div>
                 <div>
                 </div>
             </div>
         </Section>

        <Footer/>
      </Page>
    </Main>
  )
}

const NewsAndThoughtItem: FC<ArticleOrNews & {setLastImageUpdateTimestamp: (timestamp: number) => void}> = (articleOrNews) => {
    if(articleOrNews.frontmatter.templateKey === TEMPLATE_KEY_THOUGHT_ARTICLE) {
        const article = (articleOrNews as Article);
        return <div className="news-and-thoughts__grid-item"><Teaser tag="THOUGHT" image={article.frontmatter.image} type={Type.SMALL}
                       date={articleOrNews.frontmatter.date} title={article.frontmatter.title} text={article.excerpt}
                       actionPath={article.fields.slug} setLastImageUpdateTimestamp={articleOrNews.setLastImageUpdateTimestamp}/></div>
    } else {
        const news = (articleOrNews as News);
        return <div className="news-and-thoughts__grid-item"><NewsItem text={news.frontmatter.text} date={news.frontmatter.date} url={news.frontmatter.url} label={news.frontmatter.linkLabel}/></div>

    }
}

export default NewsAndThoughtsPage

export const pageQuery = graphql`
  query AllArticles($skip: Int!, $limit: Int!) {
    articles: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC}
        skip: $skip
        limit: $limit
        filter: {frontmatter: {templateKey: {in: ["news", "thought_article"]}}}
        ) {
          nodes {
            ...AllArticlesAndNews
        }
     }
  }
`
